.info-content {
    opacity: 0;
    font-size: 22px;
    transition: all 0.4s linear;

    &.active {
        opacity: 1;
    }
}

.info-content-sm {
    opacity: 0;
    font-size: 20px;

    &.active {
        opacity: 1;
    }
}

@media screen and (max-width: 1200px) {
    .info-content {
        line-height: 3px;

        &.active {
            line-height: inherit;
        }

    }

    .info-content-sm {
        line-height: 3px;

        &.active {
            line-height: inherit;
        }

    }
}