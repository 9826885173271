.copyright {
    opacity: 0.5;
}

.footer {
    // font-size: 1.5rem;
    padding: 3% 0 0% 3%;

    a {
        text-decoration: none;
        transition: ease .3s all;
        line-height: 1.8em;


        &:hover {
            color: $secondary !important;
            text-decoration: underline;
        }
    }

    p {
        line-height: 1.8em;
    }
}


@media screen and (max-width:992px) {
    .showSubMenu {
        display: none;

    }

    // .arrow {
    //     transition: all .5s ease;
    //     transform: translateY(18px);
    // }

    .arrow::after {
        content: " ";
        display: block;
        background-image: url("../assets/img/submenu-chevron.svg");
        background-repeat: no-repeat;
        height: 10px;
        width: 16px;
        position: absolute;
        border-radius: 15px;
        top: 50%;
        right: 0px;
        transition: transform .5s ease;
        transform: rotate(180deg);

    }

    .aPropos::after {
        transition: transform .5s ease;
        transform: rotate(0deg);
    }

    .legale::after {
        transition: transform .5s ease;
        transform: rotate(0deg);
    }

    .navigation::after {
        transition: transform .5s ease;
        transform: rotate(0deg);
    }

    .contact::after {
        transition: transform .5s ease;
        transform: rotate(0deg);
    }
}