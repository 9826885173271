.verticalText {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    color: #D3D3D3;
    font-size: clamp(120px, 20vw, 70pt) !important;
    font-weight: 900;
}

.subTitle {
    color: #2A2E61;
    font-size: 4rem !important;
    font-weight: 800;
}

.subtitles {
    color: $primary;
    font-weight: bolder;
    font-size: 3rem !important;
}

.orangeShadow {
    position: absolute;
    z-index: -1 !important;
    top: 0px;
    right: -200px;
}

.blueShadow {
    position: absolute;
    top: 200px;
    left: -380px;
    z-index: -1 !important;
}

.logoRight {
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


.job-underline {
    position: relative;
    z-index: 2;
    padding-bottom: 20px;
}

.job-underline::before {
    content: " ";
    display: block;
    position: absolute;
    background-color: $secondary;
    width: 50%;
    height: 5px;
    border-radius: 10px;
    left: 0;
    top: 52%;
    z-index: -1;
}

.mobileUnderline {
    position: relative;
    z-index: 2;
    padding-bottom: 22px;
}

.mobileUnderline::before {
    content: " ";
    display: block;
    position: absolute;
    background-color: $secondary;
    width: 50%;
    height: 5px;
    border-radius: 10px;
    left: 0;
    top: 52%;
    z-index: -1;
}

.pedagogiqueUnderline {
    position: relative;
    z-index: 2;
    padding-bottom: 22px;
}

.pedagogiqueUnderline::before {
    content: " ";
    display: block;
    position: absolute;
    background-color: $secondary;
    width: 50%;
    height: 5px;
    border-radius: 10px;
    left: 0;
    top: 52%;
    z-index: -1;

}


.plus {
    pointer-events: none;

    &>span {
        z-index: 101;
        position: absolute;
        display: block;
        left: 47%;
        top: 46%;
        width: 50px;
        height: 5px;
        background-color: white;
        margin: 5px;
        border-radius: 1rem;
        transition: all .5s ease;
        transform: translate(-50%, -50%);

    }

    &>span:nth-child(2) {
        transform: translate(-50%, -50%) rotate(90deg);
    }

    &.active>span:nth-child(2) {
        transform: translate(-50%, -50%) rotate(130deg);

    }

    &.active>span:nth-child(1) {
        transform: translate(-50%, -50%) rotate(50deg);

    }

}


@media screen and (max-width: 463px) {
    .mobileUnderline {
        padding-bottom: 11px;
    }

    .job-underline::before {
        content: " ";
        display: block;
        position: absolute;
        background-color: $secondary;
        width: 50%;
        height: 5px;
        border-radius: 10px;
        left: 0;
        top: 46%;
        z-index: -1;
    }

    .mobileUnderline::before {
        content: " ";
        display: block;
        position: absolute;
        background-color: $secondary;
        width: 30%;
        height: 5px;
        border-radius: 10px;
        left: 0;
        top: 74%;
        z-index: -1;
    }

    .pedagogiqueUnderline::before {
        content: " ";
        display: block;
        position: absolute;
        background-color: $secondary;
        width: 42%;
        height: 5px;
        border-radius: 10px;
        left: 0;
        top: 64%;
        z-index: -1;
    }

}


@media screen and (max-width: 1145px) {
    .logoRight {
        width: 100px;
        top: 70%;
    }

    .plus {
        &>span {
            width: 40px;
            top: 66%;
        }
    }

    .triangle {
        display: none;
    }


}