.h1-style-nousRejoindre {
  h1 {
    font-size: 30px;
    color: $primary;
  }

  p {
    color: $primary;
  }
}

.offre-h1 {
  color: $primary;
  font-size: 50px;
  font-weight: 800;
}

.nousRejoindre-reunion1 {
  width: 100%;
}

.nousRejoindre-subtitle {
  color: $primary;
  font-weight: 'lato';

  h1 {
    font-weight: 800;

    span {
      color: $secondary;
    }
  }

  p {
    font-weight: 600;
    line-height: 1.7;

    span {
      color: $secondary;
    }
  }

  div {
    font-weight: 600;
    line-height: 1.7;
  }
}

.nousRejoindre-triangle-orange {
  position: absolute;
  transform: rotate(25deg);
  left: -75px;
  z-index: -1;
  top: -110px;

}


.nousRejoindre-ellipse-orange {
  position: absolute;
  bottom: -265px;
  right: 115px;
  z-index: -1;
  width: 35%;
}

.nousRejoindre-blur-bleu {
  position: absolute;
  top: 10%;
  right: 20%;
  z-index: -1;
  width: 80%;
  height: 150%;
}

.nousRejoindre-underline::after {
  position: absolute;
  bottom: -3px;
  left: 0;
  content: "";
  height: 8px;
  background-color: #f46025;
  display: block;
  width: 100%;
  z-index: -1;
  border-radius: 205px;
}

.nousRejoindre-underline {
  white-space: nowrap;
  color: $primary !important;
}

.fixed-title-nousrejoindre {
  position: fixed;
  top: -40%;
  right: 83%;
  z-index: -1;
}

.dark-theme {
  .card-mobile-nousRejoindre {
    background-color: #121212;
    color: $dark-primary;
  }

  .nousRejoindre-underline {
    color: $dark-primary !important;
  }

  .nousRejoindre-subtitle {
    color: $darktext;

    h1 {
      span {
        color: $secondary;
      }
    }
  }

  .h1-style-nousRejoindre {
    h1 {
      font-size: 30px;
      color: $darktext;
    }

    p {
      color: $darktext;
    }
  }

  .offre-h1 {
    color: $darktext;
  }
}


@media (max-width: 500px) {

  .nousRejoindre-triangle-orange {
    right: -200vw !important;
    top: -117px !important;
  }
}

@media screen and (max-width: 1000px) {

  .nousRejoindre-triangle-orange {
    position: absolute;
    right: -236vw;
    z-index: -1;
    top: -210px;
    transform: scale(0.6) rotate(-16deg);
  }

  .nousRejoindre-reunion1 {
    margin-top: 100px;
    position: inherit;
    width: 100%;
  }

  .nousRejoindre-ellipse-orange {
    bottom: -70px;
    left: 0px;
  }

  .nousRejoindre-Reunion2 {
    display: none;
  }

  .nous-rejoindre-section2 {
    display: none;
  }

  .h1-style-nousRejoindre {
    display: none;
  }

  .nousRejoindre-carroussel-style {
    width: 70%;
    margin: auto;
  }

  .nousRejoindre-carroussel-mobile {
    display: block !important;
  }
}


//APPLICATION PAGE
.application {
  .summary-triangle {
    position: absolute;
    bottom: 0px;
    left: -190px;
    z-index: -1;
  }

  .form-rectangle {
    position: absolute;
    z-index: -1;
    top: -60px;
    left: -250px;
  }

  .form-circle {
    position: absolute;
    top: -150px;
    right: -150px;
    z-index: -1;
  }


}

.applicationForm {
  .form-control {
    background-color: #D2D4F9 !important;

    &.file {
      &.active {
        .floating-label {
          white-space: nowrap;
          top: 0px;
          left: 15px;
        }
      }

      input {
        display: none;
      }

      .floating-label {
        white-space: nowrap;
        top: 15px;
        left: 15px;
      }
    }
  }

  .floating-label {
    color: $primary !important;
    background-color: #D2D4F9 !important;
  }
}


@media screen and (max-width:992px) {
  .summary-triangle {
    // width: 200px;
    top: -80px;
    right: 80px;
    height: 100%;
  }

  .form-rectangle {
    position: absolute;
    z-index: -1;
    top: -60px;
    left: -250px;
  }

  .form-circle {
    position: absolute;
    top: -150px;
    right: -150px;
    z-index: -1;
  }
}

.dark-theme {
  .applicationForm {
    .form-control {
      border: solid 3px $primary;
    }

    .form-control {
      color: $primary !important;
    }
  }
}