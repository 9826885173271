.description {
    border: 2px solid $primary;
    border-radius: 20px;

    .hideInfos {
        display: none;
    }

    .personal-infos {
        position: relative;

        &:hover {
            cursor: pointer;
        }

        .plusIcone {
            pointer-events: none;

            &>span {
                position: absolute;
                display: block;
                left: 86%;
                top: 46%;
                width: 25px;
                height: 5px;
                background-color: white;
                margin: 5px;
                border-radius: 1rem;
                transition: all .2s ease;
                // transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);

            }

            &>span:nth-child(2) {
                transform: translate(-50%, -50%) rotate(90deg);
            }

            &.active>span:nth-child(2) {
                transform: rotate(0deg) translateY(-2px) translateX(-13px);
                // opacity: 0;
            }
        }

    }
}

.pole {
    border-radius: 50px;
    background-size: cover;
    min-height: 35vh !important;
    background-repeat: no-repeat;
}

.pole-title {
    color: white;
    font-weight: bolder;
    font-size: 1.5em;
}

.blur-bleu-equipe {
    position: absolute;
    top: -250px;
    right: -250px;
}

.blur-orange-equipe {
    position: absolute;
    top: -250px;
    left: -350px;
}

.avatar {
    position: relative;
    max-width: 16rem;

    .avatar_container {
        // background-color: red;
        aspect-ratio: 1/1.5;
        display: grid;
        align-items: end;
        border-radius: 0 0 100vw 100vw;
        overflow: hidden;
    }

    .avatar_circle {
        position: absolute;
        width: 98%;
        bottom: 0;
        filter: drop-shadow(0px 0px 10px #2a2e61);
        will-change: filter;
    }

    .avatar_img {
        position: relative;
        z-index: 2;
        transform: scale(0.5);
        left: -34px;
        bottom: 3rem;
        transition: transform 250ms ease-in-out;
    }

    .avatar_container:hover .avatar_img {
        transform: scale(0.4) translateY(-420px);
    }

    .avatar_top_orange_circle {
        position: absolute;
        top: 0;
        transform: translateY(102%);
        width: 100%;
    }

    .avatar_bottom_orange_circle {
        position: absolute;
        bottom: -2px;
        width: 100%;
        z-index: 3;
    }
}

.membre-vmobile {
    overflow: hidden;
    position: relative;
    background-color: #373A41;
    width: 226.32px;
    height: 226.32px;
    border-radius: 50%;
    filter: drop-shadow(0px 0px 10px #2a2e61);
    will-change: filter;
}

.circle {
    width: 228.90px;
    position: relative;
    z-index: 2;
}

.avatar-vmobile {
    position: absolute;
    transform: scale(0.5);
    left: -42px;
    bottom: -26rem;
}

.negative-margin {
    margin-top: -60px;
}

@media screen and (max-width:992px) {
    .pole {
        border-radius: 60px;
        background-size: cover;
        min-height: 20vh !important;
        background-repeat: no-repeat;
    }

    .avatar {
        display: none;
    }

    .negative-margin {
        margin-top: 0px;
    }
}

.dark-theme {
    .employee-text {
        color: $dark-primary;
    }
}