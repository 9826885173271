.collapsable {
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.35s ease-in-out;

    &>div {
        overflow: hidden;
    }

    &.active {
        grid-template-rows: 1fr;
    }
}

//to remove the collapsing effect from nav 
.collapsableNav {
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.35s ease-in-out;

    &>div {
        overflow: hidden;
    }

}