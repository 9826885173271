$primary: #2a2e62;
$secondary: #f46025;
$textcolor: #2a2e61;

$dark-primary: #B3B6D8;
$darktext: #A2A6C9;
$darktexts : #B3B6D8;

@import "~bootstrap/scss/bootstrap.scss";
@import "./form.scss";
@import "./card.scss";
@import "./button.scss";
@import "./switch.scss";
@import "./nav.scss";
@import "./home.scss";
@import "./footer.scss";
@import "./contact.scss";
@import "./mouse.scss";
@import "./burger.scss";
@import "./nosMetiers.scss";
@import "./title.scss";
@import "./blue-card.scss";
@import "./nousRejoindre.scss";
@import "./darkTheme.scss";
@import "./scrollUp.scss";
@import "./notFound.scss";
@import "./infoCard.scss";
@import "./collapse.scss";
@import "./nosPartenaires.scss";
@import "./equipe.scss";

body {
    overflow-x: hidden;
    font-size: 1.2em;
}

html {
    overflow-x: hidden;
}

.bg-primary {
    color: white;

    a {
        color: white;
    }
}