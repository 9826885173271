.partenaires-logo {
    background-color: white;
    // width: 300px;
    height: 120px;
    padding: 0 20px;
    max-height: 150px;
    width: 280px;
    border-radius: 10px;

}

.photos {
    filter: grayscale(100%);
    max-height: 100px;
}

.gray {
    filter: grayscale(100%);
}

.gray:hover {
    filter: grayscale(0%);
    transition: filter 0.8s ease;
}

.dark-theme {
    .text-partenaires {
        color: #B3B6D8;
    }
}

.logos {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width:992px) {
    .gray {
        filter: grayscale(0%);
    }
}