.back-top {
	position: fixed;
	right: 170px;
	bottom: 2%;
	background-color: #fff;
	transition: all 0.5s ease;
	z-index: 100;
	border-radius: 70px;
	transform: translateX(160px);
}

.no-display {
	right: 0%;
}

.back-top-visible {
	opacity: 1;
	transform: translateX(0);
}

.back-top-link {
	position: relative;
}

.back-top-arrow {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.back-top-text {
	animation: rotation 10s infinite linear;
	width: 95px;
	height: 95px;
}
