.header {
    background-image: url("../assets/img/pochoir-head.svg");
    // background-size: cover;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% 101%;
    color: white !important;
    min-height: 85vh;
    background-color: #2A2E61;
    transition: .5s ease;
    padding-top: 2%;

    & .image-wrapper {
        position: relative;
        pointer-events: none;

        .circle {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
            transform: translate(-40%, -40%);
        }

        .circle-blur {
            position: absolute;
            opacity: 0.5;
            left: 1px;
            top: 1px;
            z-index: 2
        }
    }

    .image-home-1 {
        margin-left: 22%;
    }

    .fleche-home {
        position: absolute;
        left: 50%;
        top: 65%;
        transition: linear 0.2s;

        :hover {
            height: 150px;

            .fleche-principale {
                width: 60px;
            }
        }
    }

    .struct-fleche {
        width: 100px;
        height: 100px;
        transition: linear 0.2s;
    }

    .fleche-div {
        position: absolute;
        left: 50%;
        top: 85%;
        height: 7px;
        border-radius: 25px;
        background-color: white;
        transform-origin: left;
    }

    .fleche-principale {
        transition: linear 0.1s;
        transform: rotate(-90deg);
        width: 80px;
        pointer-events: none;
    }

    .fleche-gauche {
        transform: rotate(-120deg);
        width: 40px;
        pointer-events: none;
    }

    .fleche-droite {
        transform: rotate(-60deg);
        width: 40px;
        pointer-events: none;
    }
}

.paragraph {
    padding-left: 6%;
    margin-top: 3%;
}

.dark-theme {
    .header {
        background-image: url("../assets/img/pochoir-head-dark.svg");
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .header {
        padding-left: 5rem;
    }
}

.title-1 {
    font-size: clamp(70px, 14vw, 70pt) !important;
    font-weight: 800;
    position: relative;
    color: white;
    z-index: 2;
}

.title-underline::after {
    content: " ";
    display: block;
    background-color: $secondary;
    height: 10px;
    width: 250px;
    position: absolute;
    border-radius: 15px;
    bottom: 20px;
    left: 10px;
    z-index: -1;
}

.subtitle {
    position: relative;
    z-index: 2;
    font-weight: 800 !important;
    font-size: 3rem !important;
    color: #2A2E61;
}

.dark-theme {
    .subtitle {
        color: $dark-primary;
    }
}


@media screen and (max-width:992px) {
    .header {
        padding-top: 9%;
    }

    .image-wrapper {
        transform-origin: right;
        transform: scale(.9);
    }

    .paragraph {
        padding-left: 60px;
        padding-right: 60px;
    }


    .title-1 {
        padding-top: 50px;
        font-size: 50px !important;
    }

    .fleche-home {
        display: none;
    }

    .subtitle {
        font-size: 33px !important;
    }

    .title-underline::after {
        bottom: 8px;
        left: 2px;
    }

    .paragraphe-home {
        font-size: 16px !important;
    }

    .btn {
        font-size: 14px !important;
    }

    .btn-orange:hover::after {
        display: none;
    }
}



// @media screen and (min-width:992px) and (max-width:1339px) {
//     .image-wrapper {
//         width: 100%;
//     }
// }


//Section 2

.presentation {
    color: $textcolor;

    .text-orange {
        color: $secondary !important;
    }


    .blur-bleu {
        left: -50%;
        bottom: -51%;
    }

    .forme-rond {
        position: absolute;
        top: -16%;
        left: -40%;
        z-index: 2;
    }

    .forme-ligne {
        position: absolute;
        right: -41vw;
        top: -34%;
        transform: rotate(-21deg);
    }

    .forme-triangle {
        position: absolute;
        bottom: -2%;
        right: -131%;
        // transform: rotate(-14deg);
    }
}

.blur {
    position: absolute;
    z-index: -1;
}

.dark-theme {
    .presentation {
        color: $dark-primary;
    }
}

@media only screen and (max-width: 992px) {
    .blur-bleu {
        top: -280px;
        left: 65px !important;
        z-index: 1;
    }

    .forme-rond {
        top: 82% !important;
        left: -32px !important;
        width: 24% !important;
    }

    .forme-ligne {
        top: 131% !important;
        right: -68px !important;
        width: 36% !important;
    }

    .presentation__img {
        position: inherit;
        z-index: 2;
    }

    .forme-rond {
        top: 442px;
    }

    .forme-triangle {
        bottom: -745px !important;
    }
}

// pour le responsive
.img-100 {
    max-width: 100%;
}





//Section 3 5 

.card {
    border-radius: 60px !important;
    position: relative;
    border: none !important;
    color: white !important;
}

.card-img-top {
    height: 100px !important;

}

.hr-box-home {
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
    color: $secondary;
}

.card-img-top2 {
    height: 115px !important;
    margin-bottom: 16px;

}

.card-title {
    font-weight: bold;
}

//section5
.card-invisible {
    background-color: white !important;
    color: $textcolor !important;
}

.card__separator {
    height: 7px;
    background-color: $secondary;
    border-radius: 20px;
    max-width: 100px;
    transition: all .5s ease;
}

.card:hover .card__separator {
    transform: scaleX(1.5);
}

.card .card__img {
    transition: all .5s ease;
}

.card:hover .card__img {
    transform: translateY(-5px) !important;
    pointer-events: none;
}

.card__forme {
    position: absolute;
    opacity: 0;
    transition: all ease .5s;
    width: auto !important;
}

.card__forme--rond {
    top: 0;
    left: 0;
}

.card__forme--triangle {
    right: -3px;
    bottom: -3px;
}

.card:hover .card__forme {
    opacity: 1;
    pointer-events: none;
}

.subtitle-underline::after {
    content: " ";
    // display: block;
    background-color: $secondary;
    height: 10px;
    width: 250px;
    position: absolute;
    border-radius: 15px;
    top: 45px;
    left: 0px;
    z-index: -1;
}

.blur-orange {
    pointer-events: none;
    right: -500px;
    top: -500px;
    width: 90%;
}

.dark-theme {
    .home-section5 {
        .card-invisible {
            background-color: #121212 !important;
            color: $dark-primary !important;
        }
    }
}


@media screen and (max-width:992px) {
    .blur-orange {
        right: -100px;
        top: -110px;
        width: auto;
    }

    .card-text {
        font-size: 16px;
    }
}

.owl-carousel {

    .owl-nav {
        font-size: 80px;

        .owl-next,
        .owl-prev {
            background-color: inherit !important;
            color: $secondary !important;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .owl-prev {
            left: 1rem;

        }

        .owl-next {
            right: 1rem;
        }
    }

    .owl-dots {
        .owl-dot:hover span {
            background: $secondary !important;
        }
    }

    .active span {
        background-color: $secondary !important;
    }
}

@media screen and (max-width:457px) {
    .home-section5 {
        .subtitle-underline::after {
            bottom: 6px !important;
            top: auto;
        }
    }

}

//Section 4
@media (min-width: 1200px) {
    .headert__presentation {
        padding-left: 10rem !important;
    }
}

@media only screen and (max-width: 992px) {
    .logo-iemob {
        display: none;
    }

    .div-logo {
        display: none;
    }

}

.groupe .subtitle,
.groupe p {
    color: white;
    font-size: 16px;
}

.groupe {
    .div-logo {
        display: flex !important;
        align-items: center;
        justify-content: space-around;
    }
}

.logo-iemob {
    max-width: 500px !important;
}

.underline {
    position: relative;
    z-index: 2;
    padding-bottom: 8px;
}

.underline::before {
    content: " ";
    display: block;
    position: absolute;
    background-color: $secondary;
    width: 100%;
    height: 5px;
    border-radius: 10px;
    left: 0;
    top: 70%;
    z-index: -1;
}

.radius {
    border-radius: 50px !important;
}