.burger {
    z-index: 100;

    &>span {
        display: block;
        width: 35px;
        height: 5px;
        background-color: $secondary;
        margin: 5px;
        border-radius: 1rem;
        transition: all .5s ease;
        transform-origin: left center;
    }

    &>span:nth-child(1) {
        transform: rotate(0deg);
    }

    &.active>span:nth-child(1) {
        transform: rotate(45deg) translateY(-3px);
    }

    &>span:nth-child(2) {
        transform: translateX(0);
        opacity: 1;
    }

    &.active>span:nth-child(2) {
        transform: translateX(150px);
        opacity: 0;
    }

    &>span:nth-child(3) {
        transform: rotate(0deg);
    }

    &.active>span:nth-child(3) {
        transform: rotate(-45deg) translateY(3px);
    }
}

@media screen and (min-width: 992px) {
    .burger {
        display: none;
    }
}