.floating-label {
	position: absolute;
	pointer-events: none;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	transition: 0.2s ease all;
	color: black;
	background-color: white;
	padding-left: 4px;
	padding-right: 4px;
	// transition: linear;

	&.text-area {
		top: 13%;
		transform: translateY(-50%);
	}

	&.active {
		top: 0px;
		left: 15px;
		// font-size: 11px;
		color: $secondary;
	}
}

.form-control {
	border: solid 3px $textcolor;
	border-radius: 10px !important;
	padding: 10px 0 10px 10px;
	font-size: 1.1rem;
	box-shadow: none !important;

	&.active {
		border-color: $secondary !important;
	}

	&.empty {
		border-color: #DA1919 !important;
	}
}

.tooltip {
	opacity: 1;
	width: 142px;
	background-color: #DA1919;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0px;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 75%;
	margin-left: -60px;
	transform: translateY(-120%);

	&:after {
		content: "";
		position: absolute;
		top: 100%;
		left: 75%;
		border-width: 7px;
		border-style: solid;
		border-color: #DA1919 transparent transparent transparent;
	}
}


.dark-theme {
	.floating-label {
		color: $dark-primary;
		background-color: black;
	}

	.form-control {
		border: solid 2px $dark-primary;

		&.active {
			background-color: black;
			color: $dark-primary;
		}
	}

	form {
		input {
			background-color: black;
			color: $dark-primary;
		}

		TextArea {
			background-color: black;
		}
	}
}