.infos {
    padding: 5rem 5rem 6rem 7rem;
    border-radius: 0 50px 50px 0;
    margin-left: -58px;
    margin-right: 30px;

    h2 {
        font-weight: 800;
    }

    .triangle {

        position: absolute;
        top: -30%;
        right: -80px;
        z-index: 1;
    }

    .picto-contact::after {
        content: " ";
        display: block;
        width: 23px;
        height: 32px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        left: 0;
        top: 0px;
    }

    .picto-contact-adresse::after {
        background-image: url(../assets/img/ping.svg);
        left: -5px;
    }

    .picto-contact-tel::after {
        background-image: url(../assets/img/tel.svg);
        top: 76%;
        left: -5px;
    }
}

.spreading {
    width: 1000%;
    color: black;
    position: absolute;
    top: 0;
    left: -1000%;
    height: 100%;
}

.form-contact {
    width: 70%;
}

.title {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 4rem;
    color: $textcolor;
}

.form h3 {
    color: $textcolor;
}

.dark-theme {
    .title {
        color: $dark-primary;
    }

    .contact-form-title {
        color: $dark-primary;
    }

    form {
        transition: 0.2s ease all;
    }
}

.dialog-contact {
    background-color: white;
    border: none;
    border-radius: 10px;

    .dialog-text {
        color: $primary;

    }

}

@media screen and (max-width: 1000px) {

    .contact-form-mobile {
        display: flex;
        justify-content: center;
    }

    .form-contact {
        width: 100%;
    }

    .infos {
        padding: 5rem 3rem 6rem 6rem;
    }
}