.Notfound{
  height: 450px;
  a{
    color: $primary !important;
  }
  h2{
    font-size: 35px !important;
    font-weight: 600 !important;
  }
  h3{
    font-size: 25px !important;
    font-weight: 600 !important;
  }
}

.notfound-side-title
{
  top: 50%;
}

.forme_error {
  position: absolute;
}

.forme_error-rond {
  top: 188px;
  right: 21%;
  transform: scale(0.7);

}

.forme_error-rond-2 {
  position: absolute;
  left: -66px;
  transition: all .5s ease;
}

.forme_error-rond:hover .forme_error-rond-2 {
  transform: translate(10px, 20px);
}

.forme_error-carre {
  right: 11vw;
  top: 529px;
  transform: scale(0.7);
}

.forme_error-carre-2 {
  position: absolute;
  left: -120px;
  transition: all .5s ease;
}

.forme_error-carre:hover .forme_error-carre-2 {
  transform: translate(33px, -5px) rotate(10deg);
}

.forme_error-triangle {
  position: inherit;
  margin-top: 100px;
  transform: scale(.6);
}

.forme_error-triangle-2 {
  position: absolute;
  top: -124px;
  transition: all .5s ease;
  left: 53px;
}

.forme_error-triangle:hover .forme_error-triangle-2 {
  transform: translate(-6px, 10px);
}

.dark-theme{
  .link-notFound{
    color: $dark-primary !important;
  }
}

@media only screen and (max-width: 992px) {
  .forme_error-rond {
    left: -116px;
    transform: scale(0.3);
    right: auto;
    top: -164px;
    bottom: auto;
  }
  .forme_error-carre {
 
    transform: scale(0.4);
    position: initial;
    
  }

  .forme_error-triangle {
      right: 59%;
      top: 101%;
      transform: scale(.4);
      position: initial;
      margin-top: 46px;
  }
  .Notfound{
    height: 600px;
    position: relative;
  }
  .row-notfound{
    h2{
      display: block !important;
      font-size: 2rem;
      left: 0%;
      text-align: center;
    }
  }

}

@media (max-width: 580px) {
  .notfound-side-title{
    padding-right: 0px !important;
  }
  .Notfound{
    height: 750px;
  }
}