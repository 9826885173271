.cursor {
    position: fixed;
    z-index: 1000;
    pointer-events: none;

    .content {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        border: 5px solid $secondary;
        transition: all 0.5s ease;
        animation: rotation 3s infinite linear;
        animation-play-state: paused;

        &.active {
            width: 0px;
            border-radius: 15px;
            height: 50px;
            border-width: 5px;
            backdrop-filter: grayscale(10%);
            animation-play-state: running;
        }
    }
}

@keyframes rotation {
    from {
        transform-origin: center;
        transform: rotate(0deg);
    }

    to {
        transform-origin: center;
        transform: rotate(359deg);
    }
}

@media (max-width: 1280px)
{
    .content{
        display: none;
    } 
}