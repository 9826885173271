.page-title {
  color: $primary;

  h1 {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 5rem;
  }

  h2 {
    font-weight: 800;
    font-size: 5rem;
    position: relative;
    left: 10%;
    text-transform: lowercase;
    opacity: .8;
  }
}

.aside-title {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  color: #D3D3D3;
  font-size: 9.5vh !important;
  font-weight: 900;
  overflow-wrap: break-word;
}

.absolute-title {
  position: absolute;
  top: 60%;
  right: 83%;
  white-space: nowrap;
}

.fixed-title {
  position: fixed;
  top: 2%;
  right: 83%;
  z-index: -1;
  white-space: nowrap;
}

.dark-theme {
  .page-title.page-title {
    color: $darktext;
  }

  .aside-title {
    color: #7F7F7F;
  }

  .mentionLegale-text {
    color: #7F82A0 !important;
  }
}

@media screen and (max-width: 1000px) {
  .page-title.page-title {

    h1 {
      text-align: center;
      word-break: inherit;
      font-size: 3rem;
    }

    .page-subtitle {
      font-size: 1.5rem;
      text-align: center;
      left: 0;
    }

    .hideTitle {
      display: none;
    }

  }

  .aside-title {
    position: absolute;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    color: #D3D3D3;
    font-size: 4rem !important;
    font-weight: 900;
    overflow-wrap: break-word;
    margin: 20px 0px 0px 0px;
    padding-right: 50px;
  }

  .nosProjet {
    color: #2A2E61;

    .subtitle {
      font-size: 2rem !important;
    }
  }
}

//nos projets
.nosProjet {
  color: #2A2E61;
}

.dark-theme {
  .nosProjet {
    color: $darktexts;

    .subtitle {
      color: $darktext;
      font-size: 2rem !important;
    }
  }
}