.line-blue-card {
  border: 7px solid;
  border-color: $secondary;
  border-radius: 25px;
}

.card-titre {
  width: fit-content;
}

.blueCard {
  background-color: rgba(210, 212, 249, 0.5);
  padding: 35px;
  border: 2px solid;
  border-color: rgba(210, 212, 249, 0.5);
  border-radius: 50px;
  color: $primary;
}


.btn-transparent-button {
  background-color: rgba(210, 212, 249, 0) !important;
  border: 2px solid;
  border-color: $primary !important;
  border-radius: 20px;
  color: $primary !important;
  margin-right: 40px;
  padding: 15px 75px 15px 75px;
  font-size: 0.9em !important;
}

.btn-blue-button {
  background-color: $primary !important;
  border: 2px solid;
  border-color: $primary;
  border-radius: 20px;
  color: white;
  margin-right: 40px;
  padding: 15px 67px 15px 75px;
  font-size: 0.9em !important;
}

.postulerButton {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.nousRejoindre-rectangle {
  position: absolute;
  top: -5%;
  left: -11%;
  z-index: -1;
}

.dark-theme {
  .blueCard {
    border-color: rgba(210, 212, 249, 0);
  }

}


@media screen and (max-width: 1000px) {
  .blueCard {
    // display: none;
  }

  .nousRejoindre-rectangle {
    // display: none;
  }

  .postulerButton {
    font-size: 21px !important;
  }

}