* {
    font-family: 'Lato', sans-serif !important;
}

.nav-link::after {
    content: " ";
    width: 100%;
    height: 2px;
    background-color: $secondary;
    display: block;
    transform-origin: center left;
    transform: scaleX(0);
    transition: all .5s ease;
}

.nav-link:hover::after {
    transform: scaleX(1);
}


.nav-link {
    color: white !important;
}

.navBar {
    list-style: none;

    li {
        display: inline-block;
        white-space: nowrap;
        margin-right: 10px;
    }

    .active .nav-link::after {
        transform: scaleX(1);
    }

}

.navbar-nav {
    justify-content: center;
    align-items: center;

    .nav-link {
        font-weight: bold !important;
        font-size: 1.5rem;
    }

}

/*****for mobile version*****/


.fixedNav {
    left: 0 !important;
    width: 100vw;
    z-index: 80;
    top: -100px;
    bottom: -100px;
    background-color: $primary;
    position: fixed;
    border-top-left-radius: 50%100%;
    border-bottom-left-radius: 50%100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1 !important;
    transition: left ease 0.20s;
}

.opacityTransition {
    left: 1000px;
    transition: left ease 0.6s;
}

.transitionBack {
    left: 1000px !important;
    transition: left ease 1s;
    width: 100vw;
    z-index: 80;
    top: -100px;
    bottom: -100px;
    background-color: $primary;
    position: fixed;
    border-top-left-radius: 50%100%;
    border-bottom-left-radius: 50%100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1 !important;
}

.whiteBackgrdTrans {
    left: 1000px !important;
    height: 100vh;
    width: 100vw;
    z-index: 70;
    top: 0px;
    background-color: white;
    position: fixed;
}

.whiteBackgrd {
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 70;
    top: 0px;
    background-color: white;
    position: fixed;
    transition: left ease 0.7s;
    // transition: all ease 0.3s;
}

.dark-theme {
    .whiteBackgrd {
        background-color: black;
    }
}

@media screen and (max-width:992px) {
    .fixBurger {
        top: 24px;
        right: 52px;
        padding-top: 20px;
        background-color: $primary;
        position: fixed;
        z-index: 102 !important;
    }

    .logo-educacode-header {
        height: 80px !important;
    }
}

@media screen and (min-width:1140px) {
    .navButton {
        padding: 3px 40px 3px 40px
    }
}