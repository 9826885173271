.btn {
    background-color: $secondary !important;
    color: white !important;
    border-radius: 15px !important;
    font-weight: bold !important;
    display: block;
    position: relative;
    font-size: 1.4rem !important;

    &.btn-orange-ctr {
        border: solid 2px $secondary !important;

        &:hover {
            color: $secondary !important;
            background-color: inherit !important;

        }
    }
}

.btn-transparent {
    background-color: transparent !important;
    color: $secondary !important;
    border: 2px solid $secondary !important;
}

.header__btn {
    z-index: 4;
    position: inherit;
}

.btn-orange {
    border: solid 2px $secondary !important;

    &:hover {
        background-color: $primary !important;
    }
}

.btn-orange::after {
    content: " ";
    display: block;
    background-image: url(../assets/img/bg-btn.svg);
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    top: 0;
    left: 0;
    transition: all .3s ease;
    opacity: 0;
}

.btn-orange-ctr:hover {
    background-color: inherit !important;
}

.btn-orange:hover::after {
    transform: scale(3);
    opacity: 1;
}

.btn-orange:hover {
    background-color: $secondary !important;
}